<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <h4
      class="flex items-center font-sans font-bold break-normal text-indigo-500 text-xl md:text-2xl py-2"
    >
      last Event Date
    </h4>

    <div style="display: flex;">
      <button
        type="button"
        @click="ye(2021)"
        class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
      >
        2021
      </button>

      <button
        type="button"
        @click="ye(2022)"
        class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
      >
        2022
      </button>

      <button
        type="button"
        @click="ye(2023)"
        class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
      >
        2023
      </button>
    </div>
    <div id="2021" style="display: none;">
      <div style="display: flex;">
        <button
          type="button"
          @click="aj('01', 2021)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          January
        </button>
        <button
          type="button"
          @click="aj('02', 2021)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          February
        </button>
        <button
          type="button"
          @click="aj('03', 2021)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          March
        </button>
        <button
          type="button"
          @click="aj('04', 2021)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          April
        </button>
        <button
          type="button"
          @click="aj('05', 2021)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          May
        </button>
        <button
          type="button"
          @click="aj('06', 2021)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          June
        </button>
        <button
          type="button"
          @click="aj('07', 2021)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          July
        </button>
        <button
          type="button"
          @click="aj('08', 2021)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          August
        </button>
        <button
          type="button"
          @click="aj('09', 2021)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          September
        </button>
        <button
          type="button"
          @click="aj('10', 2021)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          October
        </button>
        <button
          type="button"
          @click="aj('11', 2021)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          November
        </button>
        <button
          type="button"
          @click="aj('12', 2021)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          December
        </button>
      </div>
    </div>

    <div id="2022" style="display: none;">
      <div style="display: flex;">
        <button
          type="button"
          @click="aj('01', 2022)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          January
        </button>
        <button
          type="button"
          @click="aj('02', 2022)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          February
        </button>
        <button
          type="button"
          @click="aj('03', 2022)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          March
        </button>
        <button
          type="button"
          @click="aj('04', 2022)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          April
        </button>
        <button
          type="button"
          @click="aj('05', 2022)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          May
        </button>
        <button
          type="button"
          @click="aj('06', 2022)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          June
        </button>
        <button
          type="button"
          @click="aj('07', 2022)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          July
        </button>
        <button
          type="button"
          @click="aj('08', 2022)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          August
        </button>
        <button
          type="button"
          @click="aj('09', 2022)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          September
        </button>
        <button
          type="button"
          @click="aj('10', 2022)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          October
        </button>
        <button
          type="button"
          @click="aj('11', 2022)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          November
        </button>
        <button
          type="button"
          @click="aj('12', 2022)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          December
        </button>
      </div>
    </div>

    <div id="2023" style="display: none;">
      <div style="display: flex;">
        <button
          type="button"
          @click="aj('01', 2023)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          January
        </button>
        <button
          type="button"
          @click="aj('02', 2023)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          February
        </button>
        <button
          type="button"
          @click="aj('03', 2023)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          March
        </button>
        <button
          type="button"
          @click="aj('04', 2023)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          April
        </button>
        <button
          type="button"
          @click="aj('05', 2023)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          May
        </button>
        <button
          type="button"
          @click="aj('06', 2023)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          June
        </button>
        <button
          type="button"
          @click="aj('07', 2023)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          July
        </button>
        <button
          type="button"
          @click="aj('08', 2023)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          August
        </button>
        <button
          type="button"
          @click="aj('09', 2023)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          September
        </button>
        <button
          type="button"
          @click="aj('10', 2023)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          October
        </button>
        <button
          type="button"
          @click="aj('11', 2023)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          November
        </button>
        <button
          type="button"
          @click="aj('12', 2023)"
          class="m5 bg-green-300 rounded-full font-semibold py-2 mt-4 text-center px-4 bg-opacity-50 hover:bg-opacity-75"
        >
          December
        </button>
      </div>
    </div>

    <div id="data"></div>
  </main>
</template>
<script>
export default {
  methods: {
    ye(w) {
      if (w == 2021) {
        document.getElementById("2022").style.display = "none";
        document.getElementById("2023").style.display = "none";
        document.getElementById(w).style.display = "block";
      } else if (w == 2022) {
        document.getElementById("2021").style.display = "none";
        document.getElementById("2023").style.display = "none";
        document.getElementById(w).style.display = "block";
      } else {
        document.getElementById("2022").style.display = "none";
        document.getElementById("2021").style.display = "none";
        document.getElementById(w).style.display = "block";
      }
    },

    aj(w, y) {
      document.getElementById("data").innerHTML = "Loading";
      console.log("month" + w);
      console.log("yes" + y);
      var html = "";
      this.$axios
        .get(`https://spaceseats.io/api/ticket/past/${w}/${y}`)
        .then((res) => {
          let data = res.data.data;
          // Radhika - change
          // console.log("inside then");
          // data.sort((a, b) => {
          //   console.log(a.tixsoldindaterange);
          //   console.log(b.tixsoldindaterange);
          //   if (a.tixsoldindaterange > b.tixsoldindaterange) return 1;
          //   else return -1;
          // });
          html +=
            "<table class='border-collapse stripe hover w-full text-sm dataTable no-footer'> <thead> <tr> <th>Event</th> <th>City</th> <th>Performer ID</th> <th>Category</th> <th> Total Value </th> <th> Avg Value </th>  <th> Last Event Date </th>  <th> tix Sold in Date range </th> <th> Avg Sale Price </th>  </tr> </thead>";
          html += "<tbody>";
          for (var i = 0; i < data.length; i++) {
            html += "<tr>";
            html += "<td style='width: 250px;'>" + data[i].event + "</td>";
            html += "<td style='width: 250px;'>" + data[i].city + "</td>";
            html +=
              "<td style='width: 250px;'>" + data[i].performerid + "</td>";
            html += "<td style='width: 250px;'>" + data[i].category + "</td>";
            html += "<td style='width: 250px;'>" + data[i].totalvalue + "</td>";
            html += "<td style='width: 250px;'>" + data[i].avgvalue + "</td>";
            html +=
              "<td style='width: 250px;'>" + data[i].lasteventdate + "</td>";
            html +=
              "<td style='width: 250px;'>" + data[i].tixsoldindaterange + "yoo";
            ("</td>");
            html +=
              "<td style='width: 250px;'>" + data[i].avgsaleprice + "</td>";
            html += "</tr>";
          }
          html += "<tbody>";
          html += "</table>";

          console.log(html);
          document.getElementById("data").innerHTML = html;
        });
    },
  },
};
</script>
<style>
.m5 {
  margin-left: 20px;
}
</style>
